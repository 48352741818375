import React from "react";
import PropTypes from "prop-types";

import styles from "./Toolbar.module.css";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";

/**
 * Toolbar
 * @param {Object} props  {onDrawerToggleClick - on click to drawer toggle (function), isAuthenticated - auth or not (boolean), show: show menu(boolean)}
 */
const toolbar = (props) => (
  <header className={styles.Toolbar}>
    <DrawerToggle onClick={props.onDrawerToggleClick} />
    <div className={styles.Logo}>
      <Logo />
    </div>
    <p style={{ color: "white", fontWeight: "bold" }}>
      FOR DEMONSTRATION PURPOSES ONLY
    </p>
    {props.show ? (
      <nav>
        <NavigationItems isAuthenticated={props.isAuthenticated} />
      </nav>
    ) : null}
  </header>
);

toolbar.propTypes = {
  onDrawerToggleClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default toolbar;
