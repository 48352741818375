import React from "react";

import styles from "./Spinner.module.css";

/**
 * Spinner
 * @param {Object} props {size: oneOf(["Small", "Medium", "Large"]), show: show or not (boolean)}
 */
const spinner = (props) =>
  props.show !== false ? (
    <div className={styles.Container} data-cy="spinner">
      <i
        className={
          "fa fa-spinner fa-spin fa-3x fa-fw " +
          styles.Spinner +
          " " +
          (props.size ? styles[props.size] : "Small")
        }
      />
    </div>
  ) : null;

export default spinner;
