import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Display3d from "./Components/Viewer3D/Display3d";
import OpticalClassification from "./Components/Algorithms/OpticalClassification";
import UltrasoundSegmentation from "./Components/Algorithms/UltrasoundSegmentation";
import ImageSynthesis from "./Components/Algorithms/ImageSynthesis";
import StyleTransfer from "./Components/Algorithms/StyleTransfer";
import Layout from "./Container/Layout";

import Login from "./Components/Authentication/Login";
import { useDispatch, useSelector } from "react-redux";
import { validSessionActionCreator } from "./store/actionCreators/authenticationActionCreator";
import Optofuse3DRecords from "./Components/Viewer3D/Optofuse3DRecords";

export const OPTOFUSE_3D_LINK = "/optofuse3D"

// Main Application Class
const App = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const session = window.localStorage.getItem("session");
    if (
      session &&
      Math.abs(new Date() - new Date(session)) < 1000 * 60 * 60 // * 24
    ) {
      dispatch(validSessionActionCreator());
    }
  });

  //Not logged in
  let routes = (
    <Switch>
      <Route path={OPTOFUSE_3D_LINK} component={Optofuse3DRecords}/>
      <Route path={"/login"} component={Login} />
      <Redirect from={"/"} to={"/login"} />
      <Redirect to={"/login"} />
    </Switch>
  );
  //Logged in
  if (isAuthenticated) {
    routes = (
      <Switch>
      <Route path={OPTOFUSE_3D_LINK} component={Optofuse3DRecords}/>
        <Route path={"/3d_display"} exact component={Display3d} />
        <Route
          path={"/segmentation"}
          exact
          component={UltrasoundSegmentation}
        />
        <Route
          path={"/classification"}
          exact
          component={OpticalClassification}
        />
        <Route path={"/image_synthesis"} exact component={ImageSynthesis} />
        <Route path={"/style_transfer"} exact component={StyleTransfer} />
      </Switch>
    );
  }

  return <Layout isAuthenticated={isAuthenticated}>{routes}</Layout>;
};

export default App;
