import React from "react";
import Viewer3DDataWrapper from './Viewer3DDataWrapper';


const DATA_PATH = "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/2/3d_cube_with_dims.bin";
const DEPTH_MAP_PATH = "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/2/depth_map_petya_skin_with_dims.bin";
const OPT_IMG_PATH = "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/1/sample_v4_opt.png";

function Display3d() {

 return <Viewer3DDataWrapper dataPath={DATA_PATH} depthMapPath={DEPTH_MAP_PATH} optPath={OPT_IMG_PATH}/>
}

export default Display3d;
