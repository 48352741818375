import React from "react";
import PropTypes from "prop-types";

import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import styles from "./SideDrawer.module.css";
import Backdrop from "../../../UI/Backdrop/Backdrop";

/**
 * sideDrawer
 * @param {Object} props {isOpen: open or not (boolean), onClose: on close function (function), isAuthenticated:  auth or not (boolean), hide: hde menu (boolean)}
 */
const SideDrawer = (props) => {
  let attachedStyle = [styles.SideDrawer, styles.Close];
  if (props.isOpen) {
    attachedStyle = [styles.SideDrawer, styles.Open];
  }
  return (
    <React.Fragment>
      {props.hide ? null : (
        <React.Fragment>
          <Backdrop show={props.isOpen} onClick={props.onClose} />
          <div className={attachedStyle.join(" ")} onClick={props.onClose}>
            <div className={styles.Logo}>
              <Logo />
            </div>
            <nav>
              <NavigationItems isAuthenticated={props.isAuthenticated} />
            </nav>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

SideDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default SideDrawer;
