import React from "react";
import PropTypes from "prop-types";

import styles from "./Box.module.css";
/**
 * Box
 * @param {Object} props {children: children (inside the form)}
 */
const Box = (props) => {
  return <div className={styles.Container}>{props.children}</div>;
};
Box.propTypes = {
  children: PropTypes.node,
};
export default Box;
