import React, { useState } from "react";
import Button from "../../UI/Button/Button";
import Box from "../../UI/Box/Box";

// image synthesis
const ImageSynthesis = () => {
  const [results, setResults] = useState(""); // results (string) - in progress
  const [result_image_url, setResultImage] = useState(""); // result mask url
  const [border_width, setBorderWidth] = useState(0);

  let resultsBlob; // result mask blob

  // SYNTHESIS AWS
  const userAction = async () => {
    console.log("START SYNTHESIS");
    setResults("It will take some time to process your request...");
    try {
      const response = await fetch(
        "https://9wfpsmmj52.execute-api.eu-central-1.amazonaws.com/development",
        {
          method: "POST",
          body: "us", // string or object
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      resultsBlob = await response.blob(); //extract blob from the http response
      setResultImage(URL.createObjectURL(resultsBlob));
      setResults("");
      setBorderWidth(1);
      console.log("END SYNTHESIS");
    } catch (e) {
      console.error(e);
    }
  };



  // handle synthesis
  const handleClick = (event) => {
    event.preventDefault();
    // TODO upload input file in a SECURE! way to aws bucket
    userAction();
  };

  return (
    <Box>
      <h1>Image Synthesis</h1>
      <br /> <br />
      <Button type="submit" title="Synthesize!" onClick={handleClick}></Button>
      <br /> <br />
      <img src={result_image_url} alt="" border={border_width} width="190px" />
      <br />
      <p>{results}</p>
    </Box>
  );
};

export default ImageSynthesis;
