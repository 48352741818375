import React from "react";
import PropTypes from "prop-types";

import styles from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";
import { useDispatch } from "react-redux";
import { logoutActionCreator } from "../../../store/actionCreators/authenticationActionCreator";

/**
 *
 * @param {Object} props {isAuthenticated: auth or not (boolean)}
 */
const NavigationItems = (props) => {
  const dispatch = useDispatch();

  return (
    <ul className={styles.NavigationItems}>
      <NavigationItem link={"https://www.dermusvision.com/"} external>
        {"ABOUT"}
      </NavigationItem>
      {props.isAuthenticated ? (
        <React.Fragment>
          <NavigationItem link={"3d_display"}>
            {"3D DISPLAY"}
          </NavigationItem>
          <NavigationItem link={"segmentation"}>
            {"SEGMENTATION"}
          </NavigationItem>
          <NavigationItem link={"classification"}>
            {"CLASSIFICATION"}
          </NavigationItem>
          <NavigationItem link={"image_synthesis"}>
            {"IMAGE SYNTHESIS"}
          </NavigationItem>
          <NavigationItem link={"style_transfer"}>
            {"STYLE TRANSFER"}
          </NavigationItem>
          <NavigationItem
            link={"/"}
            exact
            onClick={() => {
              window.localStorage.removeItem("session");
              dispatch(logoutActionCreator());
            }}
          >
            {"LOGOUT"}
          </NavigationItem>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <NavigationItem link={"/login"}>{"Login"}</NavigationItem>
        </React.Fragment>
      )}
    </ul>
  );
};

NavigationItems.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default NavigationItems;
//export default React.memo(navigationItems);
