import { BufferGeometry, Float32BufferAttribute } from "three";


export default class VolumeSliceGeometry extends BufferGeometry {

	constructor(map, widthSegments = 1, heightSegments = 1, th = 1e-20 ) {

		super();
		this.type = 'VolumeSliceGeometry';
        const width = 1, height = 1;
		this.parameters = {
            th: th,
			widthSegments: widthSegments,
			heightSegments: heightSegments
		};

		const width_half = width / 2;
		const height_half = height / 2;

		const gridX = Math.floor( widthSegments );
		const gridY = Math.floor( heightSegments );

		const gridX1 = gridX + 1;

		const segment_width = width / gridX;
		const segment_height = height / gridY;

		const vertices = [];
		const normals = [];
		const uvs = [];

		for ( let iy = 0; iy < gridY; iy ++ ) {
           	
            const y1 = iy * segment_height - height_half;
            const y2 = (iy + 1) * segment_height - height_half;
            const y3 = iy * segment_height - height_half;
            const y4 = (iy + 1) * segment_height - height_half;

			for ( let ix = 0; ix < gridX; ix ++ ) {

                const x1 = ix * segment_width - width_half;
                const v1 = -map[iy*(gridX1) + ix];   
                
                const x2 = ix * segment_width - width_half;
                const v2 = -map[(iy + 1)*(gridX1) + ix];
                
                const x3 = (ix + 1) * segment_width - width_half;
                const v3 = -map[iy*(gridX1) + (ix + 1)];

                const x4 = (ix + 1) * segment_width - width_half;
                const v4 = -map[(iy + 1)*(gridX1) + (ix + 1)];
                
                if(v1 < th && v2 < th && v3 < th){
                    
                    vertices.push( x1, - y1, v1);                  
                    vertices.push( x2, - y2, v2);
                    vertices.push( x3, - y3, v3);

                    normals.push( 0, 0, 1 );
                    normals.push( 0, 0, 1 );
                    normals.push( 0, 0, 1 );

                    uvs.push( ix / gridX , 1 - ( iy / gridY ) );
                    uvs.push( ix / gridX, 1 - ( (iy + 1) / gridY ) );
                    uvs.push( (ix + 1) / gridX, 1 - ( iy / gridY ) );
                }

                if(v2 < th && v4 < th && v3 < th){

                    vertices.push( x2, - y2, v2);
                    vertices.push( x4, - y4, v4);                
                    vertices.push( x3, - y3, v3);
                    
                    normals.push( 0, 0, 1 );
                    normals.push( 0, 0, 1 );
                    normals.push( 0, 0, 1 );
                    
                    uvs.push( ix / gridX, 1 - ( (iy + 1) / gridY ) );                    
                    uvs.push( (ix + 1) / gridX, 1 - ( (iy + 1) / gridY ) );
                    uvs.push( ix / gridX, 1 - ( (iy + 1) / gridY ) );
                }    
			}
		}
		this.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );
		this.setAttribute( 'normal', new Float32BufferAttribute( normals, 3 ) );
		this.setAttribute( 'uv', new Float32BufferAttribute( uvs, 2 ) );

	}

}