import React, { useState } from "react";
//import PropTypes from "prop-types";

import styles from "./Layout.module.css";
import Toolbar from "../Components/Navigation/Toolbar/Toolbar";
import SideDrawer from "../Components/Navigation/SideDrawer/SideDrawer";
import ErrorBoundary from "../Container/ErrorBoundary";
import useWindowSize from "../utility/hooks/useWindowSize";

const MOBILE_MODE = 500;

const Layout = (props) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const size = useWindowSize();
  return (
    <ErrorBoundary>
      <Toolbar
        isAuthenticated={props.isAuthenticated}
        onDrawerToggleClick={() => setShowSideDrawer(true)}
        show={size.width >= MOBILE_MODE}
      />
      <SideDrawer
        isAuthenticated={props.isAuthenticated}
        isOpen={showSideDrawer}
        hide={size.width >= MOBILE_MODE}
        onClose={() => setShowSideDrawer(false)}
      />
      <main className={styles.Content}>{props.children}</main>
    </ErrorBoundary>
  );
};

Layout.propTypes = {};
export default Layout;
