import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import styles from "./NavigationItem.module.css";

/**
 * nav item
 * @param {Object} props  {link_ link to go (string), exact: exact match with link or not (boolean), onClick: on clcik function (function), children: children to show}
 */
const navigationItem = (props) => (
  <li className={styles.NavigationItem} data-cy="nav-li-item">
    {props.external ? (
      <a href={props.link} onClick={props.onClick} data-cy="nav-a-item">
        {props.children}
      </a>
    ) : (
      <NavLink
        to={props.link}
        exact={props.exact}
        activeClassName={styles.active}
        onClick={props.onClick}
        data-cy="nav-item"
      >
        {props.children}
      </NavLink>
    )}
  </li>
);

navigationItem.propTypes = {
  external: PropTypes.bool,
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
};
export default navigationItem;
//export default React.memo(navigationItem);
