import React, { useState } from "react";
import Button from "../../UI/Button/Button";
import Box from "../../UI/Box/Box";

// ultrasound demo images
import demo_optical from "../../assets/images/demo_optical.jpg";
import demo_ultrasound from "../../assets/images/demo_ultrasound.jpg";
import demo_histology from "../../assets/images/demo_histology.jpg";

// image synthesis
const StyleTransfer = () => {

  const [styleFile, setStyleFile] = useState(null); // style image file
  const [contentFile, setContentFile] = useState(null); // content image file

  const [style, setStyle] = useState(null);
  const [content, setContent] = useState(null);

  const [style_image_url, setStyleImage] = useState(""); // style image url
  const [content_image_url, setContentImage] = useState(""); // content image url

  const [results, setResults] = useState(""); // results (string) - in progress
  const [result_image_url, setResultImage] = useState(""); // result mask url
  const [border_width, setBorderWidth] = useState(0);

  let resultsBlob; // result mask blob

  // STYLE TRANSFER AWS
  const userAction = async (styleFile, contentFile) => {
    console.log("START STYLE TRANSFER");
    let bodyString = style + "_" + content;
    console.log(bodyString);
    setResults("It will take some time to process your request...");
    try {
      const response = await fetch(
        "https://osrtqm5v8a.execute-api.eu-central-1.amazonaws.com/development",
        {
          method: "POST",
          body: bodyString, // string or object
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      resultsBlob = await response.blob(); //extract blob from the http response
      setResultImage(URL.createObjectURL(resultsBlob));
      setResults("");
      setBorderWidth(1);
      console.log("END STYLE TRANSFER");
    } catch (e) {
      console.error(e);
    }
  };
/*
  const getFileName = () => {
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    var sec = new Date().getSeconds(); //Current Seconds
    return (
      "img_" +
      date +
      "_" +
      month +
      "_" +
      year +
      "_" +
      hours +
      "_" +
      min +
      "_" +
      sec
    );
  };*/

  // handle style transfer
  const handleClick = (event) => {
    event.preventDefault();

    // LOCAL STYLE and CONTENT IMAGE FILE
    if (styleFile && contentFile) {
      var url_style_input = URL.createObjectURL(styleFile);
      setStyleImage(url_style_input);

      var url_content_input = URL.createObjectURL(contentFile);
      setStyleImage(url_content_input);

      // TODO upload input file in a SECURE! way to aws bucket
      userAction(styleFile, contentFile);

      // DEMO IMAGE
    } else {
      console.log(style_image_url);
      console.log(content_image_url);
      fetch(style_image_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "demo_image", { type: "image/png" });

          console.log(file);

          // TODO upload demo file in a SECURE! way to aws bucket?
          userAction(file);
        });
    }
  };

  // demo style image selection
  const styleImageClick = (demo_img) => {
    setStyleFile(null);
    if (demo_img === "optical") {
      setStyleImage(demo_optical);
      setStyle("optic");
    } else if (demo_img === "histology") {
      setStyleImage(demo_histology);
      setStyle("hyst");
    } else if (demo_img === "ultrasound") {
      setStyleImage(demo_ultrasound);
      setStyle("us");
    }
    setResultImage(null);
  };

  // demo content image selection
  const contentImageClick = (demo_img) => {
    setContentFile(null);
    if (demo_img === "optical") {
      setContentImage(demo_optical);
      setContent("optic");
    } else if (demo_img === "histology") {
      setContentImage(demo_histology);
      setContent("hyst");
    } else if (demo_img === "ultrasound") {
      setContentImage(demo_ultrasound);
      setContent("us");
    }
    setResultImage(null);
  };
/*
  // local file selection
  const handleFile = (img, filetype) => {
    if (filetype === "style") {
      setStyleImage(URL.createObjectURL(img));
      setStyleFile(img);
    } else if (filetype === "content") {
      setContentImage(URL.createObjectURL(img));
      setContentFile(img);
    }
    setResultImage(null);
    console.log(img);
  };*/

  return (
    <Box>
      <h1>Style Transfer</h1>
      Select style image: <br />
      <img
        src={demo_optical}
        onClick={() => styleImageClick("optical")}
        alt="optical"
        height="120px"
      />{" "}
      <img
        src={demo_histology}
        onClick={() => styleImageClick("histology")}
        alt="histology"
        height="120px"
      />{" "}
      <img
        src={demo_ultrasound}
        onClick={() => styleImageClick("ultrasound")}
        alt="ultrasound"
        height="120px"
      />
      <br />
      <br />
      Select content image: <br />
      <img
        src={demo_optical}
        onClick={() => contentImageClick("optical")}
        alt="optical"
        height="120px"
      />{" "}
      <img
        src={demo_histology}
        onClick={() => contentImageClick("histology")}
        alt="histology"
        height="120px"
      />{" "}
      <img
        src={demo_ultrasound}
        onClick={() => contentImageClick("ultrasound")}
        alt="ultrasound"
        height="120px"
      />
      <br />
      <br />
      <Button
        type="submit"
        title="Style transfer!"
        onClick={handleClick}
      ></Button>
      <br /> <br />
      <img
        src={style_image_url}
        alt=""
        border={border_width}
        width="180px"
      />{" "}
      <img src={content_image_url} alt="" border={border_width} width="180px" />{" "}
      <img src={result_image_url} alt="" border={border_width} width="180px" />
      <br />
      <p>{results}</p>
    </Box>
  );
};

export default StyleTransfer;
