import React from "react";

import styles from "./CenterForm.module.css";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Spinner from "../../Spinner/Spinner";
/**
 * Middle form
 * @param {Object} props {title: string (title of form), error: string(error msg), pending: boolean (spinner or not), children: children (inside the form)}
 */
const CenterForm = (props) => {
  return (
    <div className={styles.Container}>
      <div data-cy="title" className={styles.Title}>
        {props.title}
      </div>
      <ErrorMessage error={props.error} />
      {props.pending ? <Spinner /> : props.children}
    </div>
  );
};

export default CenterForm;
