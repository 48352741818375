export const GET_SESSION_ERROR = "GET_SESSION_ERROR";
export const NO_USER = "NO_USER";
export const SET_SESSION = "SET_SESSION";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCESS = "LOGIN_SUCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const SIGN_UP_START = "SIGN_UP_START";
export const SIGN_UP_SUCESS = "SIGN_UP_SUCESS";

export const RESET_PSW_FAILED = "RESET_PSW_FAILED";
export const RESET_PSW_START = "RESET_PSW_START";
export const RESET_PSW_SUCESS = "RESET_PSW_SUCESS";
export const RESET_PSW_CONF_REQ = "RESET_PSW_CONF_REQ";

export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";

export const VALID_SESSION = "VALID_SESSION";
