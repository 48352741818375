import { useEffect, useRef, useState } from "react";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";

import virigis_img from "../../data/cm_viridis.png";
import gray_img from "../../data/cm_gray.png";
import VolumeSliceGeometry from "./VolumeSliceGeometry";
import { VolumeShader, VolumeSliceShader } from "./Shaders";

const Viewer3D = ({ data, width, height, depth, depthMap, optImg }) => {
  const ref = useRef();
  const [aspectRatio] = useState(window.innerWidth / window.innerHeight);
  const addGUI = (updateUniforms, colormaps) => {
    const colormapsObj = {};
    Object.keys(colormaps).forEach((key) => (colormapsObj[key] = key));
    const guiConfig = {
      x: [0, width, 1],
      y: [0, height, 1],
      z: [0, depth, 1],
      th: [0, 1, 0.001],
      colormap: colormapsObj,
      optAlpha: [0, 1, 0.001],
    };
    const guiValue = {
      optAlpha: 0.7,
      colormap: "viridis",
      x: width,
      y: height,
      z: depth,
      th: 0.0,
      helper: true,
    };
    const gui = new GUI({ container: document.getElementById("3DGUI") });

    gui
      .add(guiValue, "optAlpha", ...guiConfig.optAlpha)
      .name("Optical image opacity")
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "colormap", guiConfig.colormap)
      .name("US colormap")
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "x", ...guiConfig.x)
      .name("Z")//because of rotation
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "y", ...guiConfig.y)
      .name("X")//because of rotation
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "z", ...guiConfig.z)
      .name("Y")//because of rotation
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "th", ...guiConfig.th)
      .name("Threshold")
      .onChange(() => updateUniforms(guiValue));
    gui
      .add(guiValue, "helper")
      .name("Helper")
      .onChange(() => updateUniforms(guiValue));
  };

  const initThree = (
    w = 1800,
    h = 1800 / aspectRatio,
    bkgColor = 0xffffff,
    sortObjects = false
  ) => {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ canvas: ref.current });
    renderer.setSize(w, h, false);

    scene.background = new THREE.Color(bkgColor);
    renderer.sortObjects = sortObjects;

    return { scene, renderer };
  };

  const initCamera = (
    fov = 75,
    aspect = aspectRatio,
    near = 0.1,
    far = 5,
    pos = { x: 0.5, y: 0.5, z: 2 },
    up = [-1, 0, 0]
  ) => {
    const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    camera.position.x = pos.x;
    camera.position.y = pos.y;
    camera.position.z = pos.z;
    camera.up.set(...up);
    return camera;
  };

  const initControls = (
    camera,
    renderer,
    render,
    target = [0.5, 0.5, 0.5],
    minZoom = 0.1,
    maxZoom = 4,
    enablePan = true
  ) => {
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", render);
    controls.target.set(...target);
    controls.minZoom = minZoom;
    controls.maxZoom = maxZoom;
    controls.enablePan = enablePan;
    controls.update();
    return controls;
  };

  const create3DTexture = (
    format = THREE.RedFormat,
    type = THREE.UnsignedByteType,
    filter = THREE.LinearFilter
  ) => {
    const texture = new THREE.DataTexture3D(data, width, height, depth);
    texture.format = format;
    texture.type = type;
    texture.minFilter = texture.magFilter = filter;
    texture.unpackAlignment = 1;
    return texture;
  };

  const loadColormaps = (render) => {
    return {
      viridis: new THREE.TextureLoader().load(virigis_img, render),
      gray: new THREE.TextureLoader().load(gray_img, render),
    };
  };

  const addHelper = (scene, geometry, color = 0) => {
    const axesHelper = new THREE.AxesHelper(128);
    scene.add(axesHelper);

    const wireframe = new THREE.WireframeGeometry(geometry);
    const geometryHelper = new THREE.LineSegments(wireframe);
    geometryHelper.material.depthTest = false;
    geometryHelper.material.opacity = 0.5;
    geometryHelper.material.transparent = true;
    geometryHelper.material.color.setHex(color);
    scene.add(geometryHelper);
    return { axesHelper, geometryHelper };
  };

  const addUsVolume = (scene, usVolumeTexture, colormap) => {
    const longestAxis = Math.max(width, Math.max(height, depth));
    const uniformsVolume = THREE.UniformsUtils.clone(VolumeShader.uniforms);
    uniformsVolume["volume"].value = usVolumeTexture;
    uniformsVolume["colormap"].value = colormap;
    uniformsVolume["volume_scale"].value.set(
      width / longestAxis,
      height / longestAxis,
      depth / longestAxis
    );
    uniformsVolume["volume_dims"].value.set(width, height, depth);
    uniformsVolume["xRange"].value.set(0, 1);
    uniformsVolume["yRange"].value.set(0, 1);
    uniformsVolume["zRange"].value.set(0, 1);
    uniformsVolume["th"].value = 0.0;

    const usVolMaterial = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: uniformsVolume,
      vertexShader: VolumeShader.vertexShader,
      fragmentShader: VolumeShader.fragmentShader,
      side: THREE.BackSide,
    });

    const usVolGeometry = new THREE.BoxGeometry(1, 1, 1);
    usVolGeometry.translate(0.5, 0.5, 0.5);
    const usVolMesh = new THREE.Mesh(usVolGeometry, usVolMaterial);
    scene.add(usVolMesh);
    return { usVolMaterial, usVolGeometry, usVolMesh };
  };

  const addOptSlice = (scene, optTexture) => {
    const longestAxis = Math.max(width, Math.max(height, depth));
    const uniformsOptSlice = THREE.UniformsUtils.clone(
      VolumeSliceShader.uniforms
    );
    uniformsOptSlice["colormap"].value = optTexture;
    uniformsOptSlice["volume_scale"].value.set(
      width / longestAxis,
      height / longestAxis,
      depth / longestAxis
    );
    const optSliceMaterial = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: uniformsOptSlice,
      transparent: true,
      vertexShader: VolumeSliceShader.vertexShader,
      fragmentShader: VolumeSliceShader.fragmentShader,
    });

    const optSliceGeometry = new VolumeSliceGeometry(
      depthMap,
      height - 1,
      depth - 1
    );
    optSliceGeometry.rotateZ(Math.PI / 2);
    optSliceGeometry.rotateY(-Math.PI / 2);
    optSliceGeometry.translate(0.0, 0.5, 0.5);

    const optSliceMesh = new THREE.Mesh(optSliceGeometry, optSliceMaterial);
    scene.add(optSliceMesh);
    return { optSliceMesh, optSliceGeometry, optSliceMaterial };
  };

  useEffect(() => {
    const { scene, renderer } = initThree();
    const camera = initCamera();

    const render = () => {
      renderer.render(scene, camera);
    };

    const controls = initControls(camera, renderer, render);

    const usVolumeTexture = create3DTexture();

    const colormaps = loadColormaps(render);

    const volconfig = { colormap: "viridis" };

    const { usVolGeometry, usVolMaterial } = addUsVolume(
      scene,
      usVolumeTexture,
      colormaps[volconfig.colormap]
    );

    const { optSliceMaterial } = addOptSlice(scene, optImg);

    const { axesHelper, geometryHelper } = addHelper(scene, usVolGeometry);

    const updateUniforms = (guiValue) => {
      usVolMaterial.uniforms["colormap"].value = colormaps[guiValue.colormap];
      optSliceMaterial.uniforms["optAlpha"].value = guiValue.optAlpha;
      usVolMaterial.uniforms["xRange"].value.set(1 - guiValue.x / width, 1);
      usVolMaterial.uniforms["yRange"].value.set(1 - guiValue.y / height, 1);
      usVolMaterial.uniforms["zRange"].value.set(1 - guiValue.z / depth, 1);
      usVolMaterial.uniforms["th"].value = guiValue.th;
      geometryHelper.visible = guiValue.helper;
      axesHelper.visible = guiValue.helper;
      render();
    };
    addGUI(updateUniforms, colormaps);

    render();

    return () => {
      controls.removeEventListener("change", render);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <div id="3DGUI" style={{ position: "absolute", top: 0, left: 0, zIndex: 1000 }}></div>
      <canvas ref={ref} style={{ width: 1200, height: 1200 / aspectRatio, position: "absolute", top: 0, left: 0, zIndex: 100 }} />
    </div>
  );
};
export default Viewer3D;
