import React from "react";
import PropTypes from "prop-types";

import styles from "./ErrorMessage.module.css";
/**
 * Error msg
 * @param {Object} props {error: {message: string}}
 */
const ErrorMessage = (props) => {
  return props.error ? (
    <p className={styles.Error} data-cy="error-msg">
      {props.error.message}
    </p>
  ) : null;
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};
export default ErrorMessage;
