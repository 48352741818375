import * as action from "../actions/authenticationActions";

/**
 * Set logint start action creator
 */
export const loginStartActionCreator = () => {
  return {
    type: action.LOGIN_START,
  };
};

export const validSessionActionCreator = () => {
  return {
    type: action.VALID_SESSION,
  };
};

/**
 * Set login success action creator
 */
export const loginSuccessActionCreator = () => {
  return {
    type: action.LOGIN_SUCESS,
  };
};
/**
 * Set login failed action creator
 */
export const loginFailedActionCreator = (error) => {
  return {
    type: action.LOGIN_FAILED,
    payload: { error },
  };
};
/**
 * Set lget session action creator
 */
export const getSessionErrorActionCreator = () => ({
  type: action.GET_SESSION_ERROR,
});
/**
 * Set set session  action creator
 */
export const setSessionActionCreator = (session) => ({
  type: action.SET_SESSION,
  payload: {
    session,
  },
});

/**
 * Set logout action creator
 */
export const logoutActionCreator = () => {
  return {
    type: action.LOGOUT,
  };
};

/**
 * Set clear auth state action creator
 */
export const clearAuthStateActionCreator = () => {
  return {
    type: action.CLEAR_AUTH_STATE,
  };
};
