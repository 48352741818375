import React, { useState, useRef } from "react";
import Button from "../../UI/Button/Button";
import Box from "../../UI/Box/Box";

// ultrasound demo images
import demo_hitachi from "../../assets/images/demo_hitachi.png";
import demo_harcsa from "../../assets/images/demo_harcsa.png";
import demo_cortex from "../../assets/images/demo_cortex.png";

// ultrasound segmentation
const UltrasoundSegmentation = () => {
  const fileInput = useRef(); // image file from local
  const [inputFile, setInputFile] = useState(null); // input image file
  const [input_image_url, setInputImage] = useState(""); // input image url
  const [results, setResults] = useState(""); // results (string) - in progress
  const [result_image_url, setResultImage] = useState(""); // result mask url
  const [border_width, setBorderWidth] = useState(0);

  let resultsBlob; // result mask blob

  // SEGMENTATION AWS
  const userAction = async (file) => {
    console.log("START SEGMENTATION");
    setResults("It will take some time to process your request...");
    try {
      const response = await fetch(
        "https://t960gm1bcf.execute-api.eu-central-1.amazonaws.com/development",
        {
          method: "POST",
          body: file, // string or object
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      );
      resultsBlob = await response.blob(); //extract blob from the http response
      setResultImage(URL.createObjectURL(resultsBlob));
      setResults("");
      setBorderWidth(1);
      console.log("END SEGMENTATION");
    } catch (e) {
      console.error(e);
    }
  };
/*
  const getFileName = () => {
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    var sec = new Date().getSeconds(); //Current Seconds
    return (
      "img_" +
      date +
      "_" +
      month +
      "_" +
      year +
      "_" +
      hours +
      "_" +
      min +
      "_" +
      sec
    );
  };*/

  // handle segmentation
  const handleClick = (event) => {
    event.preventDefault();

    // LOCAL IMAGE FILE
    if (inputFile) {
      //newFileName = getFileName();
      var url_input = URL.createObjectURL(inputFile);
      setInputImage(url_input);

      // TODO upload input file in a SECURE! way to aws bucket
      userAction(inputFile);

      // DEMO IMAGE
    } else {
     // newFileName = "ultrasound_demo_image";
      console.log(input_image_url);
      fetch(input_image_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "demo_image", { type: "image/png" });

          console.log(file);

          // TODO upload demo file in a SECURE! way to aws bucket?
          userAction(file);
        });
    }
  };

  // demo image selection
  const imageClick = (demo_img) => {
    setInputFile(null);
    if (demo_img === "hitachi") {
      setInputImage(demo_hitachi);
    } else if (demo_img === "harcsa") {
      setInputImage(demo_harcsa);
    } else if (demo_img === "cortex") {
      setInputImage(demo_cortex);
    }
    setResultImage(null);
  };

  // local file selection
  const handleFile = (img) => {
    setInputImage(URL.createObjectURL(img));
    setInputFile(img);
    setResultImage(null);
    console.log(img);
  };

  return (
    <Box>
      <h1>Ultrasound Image Segmentation</h1>
      Select demo ultrasound image: <br />
      <img
        src={demo_hitachi}
        onClick={() => imageClick("hitachi")}
        alt="hitachi"
        height="140px"
      />{" "}
      <img
        src={demo_harcsa}
        onClick={() => imageClick("harcsa")}
        alt="harcsa"
        height="140px"
      />{" "}
      <img
        src={demo_cortex}
        onClick={() => imageClick("cortex")}
        alt="cortex"
        height="140px"
      />
      <br />
      <br />
      <label>
        Select ultrasound image file from drive: <br />
        <input
          type="file"
          ref={fileInput}
          onChange={(event) => {
            const fileUploaded = event.target.files[0];
            handleFile(fileUploaded);
          }}
        />
      </label>
      <br /> <br />
      <Button type="submit" title="Segment!" onClick={handleClick}></Button>
      <br /> <br />
      <img
        src={input_image_url}
        alt=""
        border={border_width}
        width="380px"
      />{" "}
      <img src={result_image_url} alt="" border={border_width} width="380px" />
      <br />
      <p>{results}</p>
    </Box>
  );
};

export default UltrasoundSegmentation;
