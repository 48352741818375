import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as action from "../../store/actionCreators/authenticationActionCreator";

import useUserInput, {
  USERNAME_TYPE,
  PSW_TYPE,
} from "../../UI/CustomInput/useUserInput";
import ErrorMessage from "../../UI/Divs/ErrorMessage/ErrorMessage";
import Button from "../../UI/Button/Button";
import CenterFrom from "../../UI/Divs/CenterForm/CenterForm";

const Login = () => {
  //Username input
  const [usernameComponent, username] = useUserInput(USERNAME_TYPE);
  //Pasw input
  const [pswComponent, psw, setPsw] = useUserInput(PSW_TYPE);
  //Auth status pending
  const pending = useSelector((state) => state.auth.pending);
  //Auth status error
  const error = useSelector((state) => state.auth.error);

  const dispatch = useDispatch();

  //Clear psw on error
  useEffect(() => {
    if (error) {
      setPsw({ value: "", valid: false, touched: false });
    }
  }, [error, setPsw]);

  // TODO
  const login = (username, password) => {
    if (!username.valid) {
      dispatch(action.loginFailedActionCreator("Username is not valid!"));
      return;
    }
    if (!password.valid || !password.touched) {
      dispatch(action.loginFailedActionCreator("Password is not valid!"));
      return;
    }

    var crypto = require("crypto");
    const hash = crypto.createHash("sha256").update(password).digest("base64");

    if (
      dispatch &&
      username.value.localeCompare("dermusvision") === 0 &&
      hash.localeCompare("2laYvhe5tGliM1eZd5++yozl1JHA0mJDuv756hg3qdg=") === 0
    ) {
      window.localStorage.setItem("session", new Date());
      dispatch(action.loginSuccessActionCreator());
    } else {
      dispatch(
        action.loginFailedActionCreator(
          "Please add valid username and password"
        )
      );
    }
  };

  //On login handler
  const submitHandler = (event) => {
    event.preventDefault();
    // TODO own login function to add
    login(username, psw);
  };

  return (
    <CenterFrom title={"Sign in"} pending={pending}>
      <ErrorMessage error={{ message: error }} />
      <form onSubmit={submitHandler}>
        {usernameComponent}
        {pswComponent}
        <Button title={"Sign in"} />
      </form>
    </CenterFrom>
  );
};

/*onSubmit={this.submitHandler}*/
export default Login;
