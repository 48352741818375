import React from "react";
import PropTypes from "prop-types";

import styles from "./Button.module.css";

const button = (props) =>
  props.disabled ? (
    <button
      onClick={props.onClick ? props.onClick : null}
      className={styles.Button}
      data-cy="button"
      disabled
    >
      {props.title}
    </button>
  ) : (
    <button
      onClick={props.onClick ? props.onClick : null}
      className={styles.Button}
      data-cy="button"
    >
      {props.title}
    </button>
  );

button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default button;
