import * as actionType from "../actions/authenticationActions";

const initState = {
  isAuthenticated: false,
  error: null,
  pending: false,
  info: false,
};

const authenticationReducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.LOGOUT:
      return { ...state, isAuthenticated: false };
    case actionType.LOGIN_START:
      return { ...initState, pending: true };
    case actionType.LOGIN_SUCESS:
      return {
        ...initState,
        pending: false,
        isAuthenticated: true,
      };
    case actionType.LOGIN_FAILED:
      return { ...initState, pending: false, error: action.payload.error };
    case actionType.CLEAR_AUTH_STATE:
      return {
        ...state,
        error: null,
        pending: false,
        info: false,
      };
    case actionType.VALID_SESSION:
      return {
        ...state,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
