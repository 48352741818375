import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { TextureLoader } from "three";
import Viewer3D from "./Viewer3D";

import * as JSZip from 'jszip';
import Progressbar from "../../UI/Progressbar/Progressbar";

const LOADING = "Loading data... "
const NO_DATA_OR_NO_ACCESS = "Data is not found or access is not guaranteed"

const Viewer3DDataWrapper = ({isZip, dataPath, depthMapPath, optPath}) => {
  //3D data
  const [data, setData] = useState();

  //Depth
  const [depth, setDepth] = useState();
  //Width
  const [width, setWidth] = useState();
  //Height
  const [height, setHeight] = useState();
  //Opt image
  const [optImg, setOptImg] = useState();
  //Depth map
  const [depthMap, setDepthMap] = useState();
  //NO file or access
  const [noFileOrAccess, setNoFileOrAccess] = useState();
  //Percent
  const [percent, setPercent] = useState(0);


  const fecthData = async (path) =>{
    const fetchResult = await fetch(path);
    if (fetchResult.status === 200 || fetchResult.status === 0) {
      const reader = fetchResult.body.getReader();
      const contentLength = +fetchResult.headers.get('Content-Length');
      let receivedLength = 0; // received that many bytes at the moment
      let chunks = []; // array of received binary chunks (comprises the body)
      while(true) {
        const {done, value} = await reader.read();
        if (done) {
          break;
        }
        chunks.push(value);
        receivedLength += value.length;
        setPercent(Math.round(receivedLength/contentLength*100))
      }
      // Step 4: concatenate chunks into single Uint8Array
      let chunksAll = new Uint8Array(receivedLength); // (4.1)
      let position = 0;
      for(let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
      }
      return chunksAll;
    }else {
        setNoFileOrAccess(NO_DATA_OR_NO_ACCESS);
      }
  }
  /**
   * Load zip file
   * @param {String} path path to zip filr
   */
  const loadZipFile = async (path) => {
    const chunksAll = await fecthData(path)
    if(chunksAll){
      const blob = new Blob([chunksAll], {type : 'application/zip'})
      const zip = await JSZip.loadAsync(blob)
      const array3D = await zip.file("3D_data/3d_cube.bin").async("uint8array")
      const size = process3DData(array3D)
      const dm = await zip.file("3D_data/depth_map.bin").async("arraybuffer")
      processDepthMap(new Float32Array(dm), size.d)
      const img = await zip.file("3D_data/optical_img.png").async("base64")
      new TextureLoader().load("data:image/png;base64," + img, e=>{setOptImg(e)});
   }
  }
  //Process 3D data (uint8Array)
  const process3DData = (array) => {
    const w = (array[0]<<8) + array[1]
    const h = (array[2]<<8) + array[3]
    const d = (array[4]<<8) + array[5]
    setWidth(w)
    setHeight(h)
    setDepth(d)
    setData(array.slice(6));
    return {w,h,d}
  }

  //Process depth map (arrayD: data - Float32Array,)
  const processDepthMap = (arrayD,d) => {
    arrayD.forEach((element, ind) => {
      arrayD[ind] = arrayD[ind] / d
      
    });

    setDepthMap(arrayD.slice(2));
  }
  useEffect(() => 
  {const controller = new AbortController();
    if(isZip===null || isZip === undefined || !isZip) {
      
      axios({
        url: dataPath,
        method: "GET",
        responseType: "arraybuffer",
        signal: controller.signal,
      }).then((response) => {
        const size = process3DData(new Uint8Array(response.data));
        axios.get(depthMapPath, {
          responseType: "arraybuffer",
          signal: controller.signal
        }).then((response) => {
          processDepthMap(new Float32Array(response.data), size.d)          
          new TextureLoader().load(optPath, e=>{setOptImg(e)});
        });
      });
      
    }else{    
      loadZipFile(dataPath);
    }
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line
  }, []);
  
  return (
    <Fragment>
     
      {data && width && height && depth && depthMap && optImg ? 
        <Viewer3D
          data={data}
          width={depth}//because of rotation
          height={height}
          depth={width}//because of rotation
          depthMap={depthMap}
          optImg={optImg}
        />
      : (isZip===null || isZip === undefined || !isZip) ? LOADING :noFileOrAccess ? noFileOrAccess : <div style={{width: "90%", margin: "0 auto"}}>
         <Progressbar percent={percent} title={LOADING}/>
      </div> }
    </Fragment>
  );
};

export default Viewer3DDataWrapper;
