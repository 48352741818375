import React, { useState, useRef, useEffect } from "react";
import Box from "../../UI/Box/Box";
import Button from "../../UI/Button/Button";

import CanvasJSReact from "../../UI/canvasjs.react";
import styles from "./OpticalClassification.module.css";

// optical demo images
import demo_nevus from "../../assets/images/demo_nevus.jpg";
import demo_bcc from "../../assets/images/demo_bcc.jpg";
import demo_mela from "../../assets/images/demo_mela.jpg";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// optical classification
const OpticalClassification = () => {
  const fileInput = useRef(); // image file from local
  const [inputFile, setInputFile] = useState(null); // input image file
  const [input_image_url, setInputImage] = useState(""); // input image url
  const [results, setResults] = useState(""); // results (string)
  const [showBarchart, setShowBarchart] = useState(false);

  let chartRef = useRef();

  const [classificationResults, setClassificationResults] = useState({
    AKIEC: 0,
    BCC: 0,
    BKL: 0,
    DF: 0,
    MEL: 0,
    NV: 0,
    VASC: 0,
  });

  let resultsJson; // results json

  // CLASSIFICATION AWS
  const userAction = async (file) => {
    console.log("START CLASSIFICATION");
    setResults("It will take some time to process your request...");
    try {
      const response = await fetch(
        "https://lqwfivgpwe.execute-api.eu-central-1.amazonaws.com/development/",
        {
          method: "POST",
          body: file, // string or object
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        }
      );
      resultsJson = await response.json(); //extract JSON from the http response

      setClassificationResults({
        AKIEC: parseFloat(resultsJson.AKIEC),
        BCC: parseFloat(resultsJson.BCC),
        BKL: parseFloat(resultsJson.BKL),
        DF: parseFloat(resultsJson.DF),
        MEL: parseFloat(resultsJson.MEL),
        NV: parseFloat(resultsJson.NV),
        VASC: parseFloat(resultsJson.VASC),
      });

      setResults(
        ""
        /*"Results:" + " Actinic Keratosis: " + resultsJson.AKIEC + " Basal Cell Carcinoma: " + resultsJson.BCC + 
        " Benign Keratosis: " + resultsJson.BKL + " Dermatofibroma: " + resultsJson.DF + 
          " Melanoma: " + resultsJson.MEL + " Nevus: " + resultsJson.NV + " Vascular lesion: " + resultsJson.VASC*/
      );

      setShowBarchart(true);

      console.log("END CLASSIFICATION");
    } catch (e) {
      console.error(e);
    }
  };


  // handle classification
  const handleClick = (event) => {
    event.preventDefault();

    // LOCAL IMAGE FILE
    if (inputFile) {
    // let newFileName = getFileName();
      var url_input = URL.createObjectURL(inputFile);
      setInputImage(url_input);

      // TODO upload input file in a SECURE! way to aws bucket
      userAction(inputFile);

      // DEMO IMAGE
    } else {
     // let newFileName = "optical_demo_image";
      console.log(input_image_url);
      fetch(input_image_url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "demo_image", { type: "image/png" });

          // TODO upload demo file in a SECURE! way to aws bucket?
          userAction(file);
        });
    }
  };

  // demo image selection
  const imageClick = (demo_img) => {
    setInputFile(null);
    if (demo_img === "nevus") {
      setInputImage(demo_nevus);
    } else if (demo_img === "bcc") {
      setInputImage(demo_bcc);
    } else if (demo_img === "mela") {
      setInputImage(demo_mela);
    }
    setResults("");
    setShowBarchart(false);
  };

  // local file selection
  const handleFile = (img) => {
    setInputImage(URL.createObjectURL(img));
    setInputFile(img);
    setResults("");
    setShowBarchart(false);
    console.log(img);
  };

  useEffect(() => {
    //chart.render();
  }, [classificationResults]);

  return (
    <Box>
      <h1>Optical Image Classification</h1>
      Select demo optical image: <br />
      <img
        src={demo_nevus}
        onClick={() => imageClick("nevus")}
        alt="nevus"
        height="180px"
      />{" "}
      <img
        src={demo_bcc}
        onClick={() => imageClick("bcc")}
        alt="bcc"
        height="180px"
      />{" "}
      <img
        src={demo_mela}
        onClick={() => imageClick("mela")}
        alt="mela"
        height="180px"
      />
      <br />
      <br />
      <label>
        Select optical image file from drive: <br />
        <input
          type="file"
          ref={fileInput}
          onChange={(event) => {
            const fileUploaded = event.target.files[0];
            handleFile(fileUploaded);
          }}
        />
      </label>
      <br /> <br />
      <Button
        type="submit"
        title="Classify!"
        onClick={handleClick}
      ></Button>{" "}
      {results}
      <br /> <br />
      <div className={styles.row}>
        <div className={styles.left}>
          <img src={input_image_url} alt="" width="300px" />
        </div>
        <div className={styles.right}>
          {showBarchart && (
            <CanvasJSChart
              options={{
                animationEnabled: true,
                theme: "light1",
                axisY: {
                  title: "Probability Score",
                  includeZero: true,
                  //labelFormatter: this.addSymbols,
                },
                width: 300,
                height: 250,
                data: [
                  {
                    type: "bar",
                    dataPoints: [
                      {
                        label: "Actinic Keratosis",
                        y: classificationResults.AKIEC,
                      },
                      {
                        label: "Basal Cell Carcinoma",
                        y: classificationResults.BCC,
                      },
                      {
                        label: "Benign Keratosis",
                        y: classificationResults.BKL,
                      },
                      { label: "Dermatofibroma", y: classificationResults.DF },
                      { label: "Melanoma", y: classificationResults.MEL },
                      { label: "Nevus", y: classificationResults.NV },
                      {
                        label: "Vascular lesion",
                        y: classificationResults.VASC,
                      },
                    ],
                  },
                ],
              }}
              onRef={(ref) => (chartRef.current = ref)}
              key={JSON.stringify(classificationResults)}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default OpticalClassification;
