import { Fragment, useState } from "react";
import {  useLocation } from "react-router";
import { OPTOFUSE_3D_LINK } from "../../App";
import Viewer3DDataWrapper from "./Viewer3DDataWrapper";

const records = {
    "86028d46-17ae-476e-ae86-c009ae3b0c4c" : {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/2/3d_cube_with_dims.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/2/depth_map_petya_skin_with_dims.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/1/sample_v4_opt.png",
    },
    "e6464e1b-a50f-443d-a58c-a7f0b62436b2": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/4/3d_cube_greg_2.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/4/depth_map_greg_2.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/4/opt_image_greg_2.png",
    },
    "01477a22-67c2-451d-9174-8fc0a18ea731": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/5/3d_cube_petya_bal_kar.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/5/depth_map_petya_bal_kar.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/5/petya_arm_cropped.png",
    },
    "50572f80-305b-40e3-9a34-64204f90c5a7": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/6/3d_cube_petya_bal_kar2.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/6/depth_map_petya_bal_kar2.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/6/petya_arm_cropped2.png",
    },
    "1613c68e-515e-42c8-ad5e-44a3769b4984": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/7/3d_cube_petya_bal_kar3.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/7/depth_map_petya_bal_kar3.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/7/petya_arm_cropped3.png",
    },
    "7cd09413-4328-4d66-91b5-387008761c81": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/8/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/8/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/8/left_arm_new_forming_lesion_optical_image.png",
    },
    "cf4b182d-d883-4862-a575-1a5b3a7d503f": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/9/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/9/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/9/left_arm_new_forming_lesion_optical_image.png",
    },
    "ef20a25a-1029-4a6f-9489-f8a09c373efe": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/10/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/10/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/10/left_arm_new_forming_lesion_optical_image.png",
    },
    "cae59011-9ca1-48a1-a159-d5b600bfa40b": {
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/11/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/11/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/11/left_arm_new_forming_lesion_optical_image.png",
    },
    // VEVO DATA
    "848b5d84-12a0-43dc-ac8b-825c336ab854": {   // VEVO SLOW3 WITHOUT PREPROC by P 14
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/14/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/14/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/14/left_arm_new_forming_lesion_optical_image.png",
    },
    "2cee933f-1169-43af-886d-97781e160c42": {   // VEVO SLOW3 WITH  PREPROC by P 16
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/16/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/16/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/16/left_arm_new_forming_lesion_optical_image.png",
    },
    
    "d42ffc56-4e22-4dc9-bbd1-b7a177e7ba1b": { //VEVO FAST3 WITHOUT PREPROC by D
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/13/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/13/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/13/left_arm_new_forming_lesion_optical_image.png"
    },
    "a83f5827-1b77-45fa-a76c-861f8465dbec": {//VEVO FAST3 WITHOUT PREPROC, WITH 0.03 th by D
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/15/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/15/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/15/left_arm_new_forming_lesion_optical_image.png"
    },
    "2240ff9e-5c3f-4b86-b9a4-4bc87cdb2b71":{//VEVO FAST3 WITH PREPROC by D
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/17/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/17/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/17/left_arm_new_forming_lesion_optical_image.png"
    },
    // CORRECTION!
    "0db6b84c-01c6-45d0-8803-ce873ef21ef1": {   //SLOW3 not perfect
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/20/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/20/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/20/left_arm_new_forming_lesion_optical_image.png",
    },
    "f9fc5e8d-647e-4222-94b0-5b3b5a1f524d": {   //FAST3
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/21/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/21/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/21/left_arm_new_forming_lesion_optical_image.png",
    },
    "04fd6d42-e030-4447-b8d3-054de368da04": {   //SLOW3 CORRECTED - best so far
        dataPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/22/left_arm_new_forming_lesion_3d_cube.bin",
        depthMapPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/22/left_arm_new_forming_lesion_depth_map.bin",
        optPath: "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/22/left_arm_new_forming_lesion_optical_image.png",
    },
    
    
   
}
const basePath = "https://algo-image-dermus.s3.eu-central-1.amazonaws.com/3D/"
const zipDataPath = "3D_data.zip"

const Optofuse3DRecords = () =>{
    
    const location = useLocation();
    const [recId] = useState(location.pathname.substring(OPTOFUSE_3D_LINK.length + 1));

    return <Fragment>
    {records[recId]? 
    <Viewer3DDataWrapper dataPath={records[recId].dataPath} depthMapPath={records[recId].depthMapPath} optPath={records[recId].optPath}/>:
    <Viewer3DDataWrapper 
        isZip={true}
        dataPath={basePath + recId + "/" + zipDataPath}/>}
    </Fragment>
    
    
    
}
export default Optofuse3DRecords;